<template>
  <a @click="$emit('changeTheme')">
    <img :src="icon" alt="" />
  </a>
</template>

<script>
import state from "@/utils/store";

export default {
  data() {
    return {
      iconDark: require("@/assets/icons/dark.svg"),
      iconLight: require("@/assets/icons/light.svg"),
    };
  },
  computed: {
    darkMode() {
      return state.darkMode;
    },
    icon() {
      return this.darkMode ? this.iconDark : this.iconLight;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
a {
  position: fixed;
  left: 0;
  top: 5rem;
  background-color: var(--color-clear);
  z-index: 7;
  cursor: default;
  width: 2.5rem;
  height: 2.5rem;
  padding: .5rem;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  img{
    width: 100%;
    height: 100%;
    filter: var(--filter-invert-1x);
  }
}
</style>
