import { createRouter, createWebHistory } from "vue-router";
import { i18n } from "@/main.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/V-Home.vue"),
    meta: { anim: "home", pageable : 1 },
  },
  {
    path: "/about",
    name: "AboutMe",
    component: () => import("@/views/V-AboutMe.vue"),
    meta: { anim: "about", pageable : 2 },
  },
  {
    path: "/skills",
    name: "Skills",
    component: () => import("@/views/V-Skills.vue"),
    meta: { anim: "skills", pageable : 3 },
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("@/views/V-Services.vue"),
    meta: { anim: "services", pageable : 4 },
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("@/views/V-Projects.vue"),
    meta: { anim: "projects", pageable : 5 },
  },
  {
    path: "/contact",
    name: "ContactMe",
    component: () => import("@/views/V-ContactMe.vue"),
    meta: { anim: "contact", pageable : 6 },
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("@/views/V-Main.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/V-Test.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // i18n.locale= localStorage.getItem('language') || 'en';
  i18n.locale = "en";
  return next();
});

export default router;
