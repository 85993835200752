export default {
  data() {
    return {
      lightTheme: null,
    };
  },
  mounted() {
    this.lightTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        this.lightTheme = event.matches;
      });
  },
};
