<template>
  <main>
    <TheTheme @changeTheme="handlerTheme" />
    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.anim" mode="out-in">
        <component :is="Component" />
      </transition>
      <ThePagination />
    </router-view>
  </main>
</template>

<script>
import TheTheme from "@/components/TheTheme.vue";
import ThePagination from "@/components/ThePagination.vue";
import schemeColorListener from "@/mixins/schemeColorListener";
import state from "@/utils/store";

export default {
  name: "App",
  head() {
    return {
      title: "FelipeGCx",
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      link: [
        {
          rel: "icon",
          href: this.icon,
          type: "image/*",
        },
      ],
    };
  },
  components: {
    TheTheme,
    ThePagination,
  },
  mixins: [schemeColorListener],
  data() {
    return {
      page: 0,
      routes: null,
      icon: require("@/assets/favicons/favicon_light.png"),
      secretCommand: [],
      secretCode: [
        "ArrowRight",
        "ArrowRight",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "ArrowDown",
        "ArrowRight",
        "ArrowLeft",
      ],
      audio: null,
      beat: null,
      darkMode: state.darkMode,
    };
  },
  watch: {
    lightTheme() {
      this.getIcon();
    },
    // currentTheme(value) {
    //   console.log("cambia en App",value)
    //   this.model = value == "light";
    // },
  },
  methods: {
    getIcon() {
      let icon = require("@/assets/favicons/favicon_light.png");
      const brands = navigator.userAgent.split(" ");
      const brand = brands[brands.length - 1];
      if (brand.includes("Firefox")) {
        icon = require("@/assets/favicons/favicon_dark.gif");
      } else if (brand.includes("Edg")) {
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          icon = require("@/assets/favicons/favicon_dark.png");
        }
        // here i need support gtk scheme color
      } else {
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          icon = require("@/assets/favicons/favicon_dark.png");
        }
      }
      this.icon = icon;
    },
    getPath(relative) {
      // relative is a boolean parameter, false mean previous path, and true next path
      let positionRequired = relative ? this.page + 1 : this.page - 1;
      for (let index = 0; index < this.routes.length; index++) {
        const element = this.routes[index];
        if (element.meta.pageable) {
          if (positionRequired == element.meta.pageable) {
            return element.path;
          }
        }
      }
    },
    getLast() {
      let idxLast = this.routes.length - 1;
      for (let index = idxLast; index >= 0; index--) {
        const element = this.routes[index];
        if (element.meta.pageable) {
          return element.path;
        }
      }
    },
    pageListener() {
      window.addEventListener("keydown", (e) => {
        this.page = this.$route.meta.pageable;
        let path;
        if (e.key === "PageUp") {
          path = this.getPath(false);
        } else if (e.key === "PageDown") {
          path = this.getPath(true);
        } else if (e.key === "Home") {
          path = this.routes[0].path;
        } else if (e.key === "End") {
          path = this.getLast();
        }
        if (path) {
          this.$router.push(path);
        }
      });
    },
    commandListener() {
      window.addEventListener("keydown", (e) => {
        this.secretCommand.push(e.key);
        // this.audio = require("@/assets/music/HIGHEST_IN_THE_ROOM.mp3");
        this.audio = require("@/assets/music/HIGHEST_IN_THE_ROOM.aac");
        if (this.secretCommand.length > this.secretCode.length) {
          this.secretCommand.shift();
        }
        if (
          JSON.stringify(this.secretCommand) === JSON.stringify(this.secretCode)
        ) {
          this.beat = new Audio(this.audio);
          // console.log("¡Comando oculto activado!");
          this.beat.play();
          this.secretCommand = [];
        }
      });
    },
    handlerTheme() {
      let theme = this.darkMode ? "light" : "dark";
      // let theme = this.isDark ? "light" : "dark";
      // let theme = this.currentTheme == "light" ? "dark" : "light";
      localStorage.setItem("theme", theme);
      this.configTheme();
    },
    configTheme() {
      let lsTheme = localStorage.getItem("theme");
      if (lsTheme == null || lsTheme == undefined) {
        const prefers = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        localStorage.setItem("theme", prefers ? "dark" : "light");
        lsTheme = prefers ? "dark" : "light";
      }
      lsTheme = lsTheme == "light" ? "light" : "dark";
      localStorage.setItem("theme", lsTheme);
      this.darkMode = lsTheme == "dark";
      state.darkMode = this.darkMode;
      document.body.classList.remove("app-dark");
      document.body.classList.remove("app-light");
      document.body.classList.add(
        lsTheme == "light" ? "app-light" : "app-dark"
      );
    },
  },
  mounted() {
    let lan = window.navigator.language;
    if (lan.includes("es")) {
      this.$i18n.locale = "es";
    }
    this.routes = this.$router.getRoutes();
    this.pageListener();
    this.commandListener();
    this.configTheme();
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Hanson";
  src: url("./assets/fonts/webfontkit/hanson-bold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/webfontkit/hanson-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenericTechno";
  src: url("./assets/fonts/webfontkit/generictechno-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/webfontkit/generictechno-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Joyride Round";
  src: url("./assets/fonts/webfontkit/joyride-round-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/webfontkit/joyride-round-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lack";
  src: url("./assets/fonts/webfontkit/lack-regular-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/webfontkit/lack-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Project Space";
  src: url("./assets/fonts/webfontkit/project_space-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/webfontkit/project_space-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.app-dark {
  --bg-main: #1a1a1a;
  --color-clear: white;
  --color-dark: black;
  --tag-color: #ed0318;
  --color-one: #ed03ce;
  --color-two: #07fbff;
  --color-three: #010df6;
  --op-color: #1e1e1e87;
  --width-scrollbar: 0.8rem;
  --filter-invert-06x: none;
  --filter-invert-1x: none;
  --filter-drop-1x: drop-shadow(2px 4px 6px black);
  --font-size-600: 600;
  --bg-image-home: url("./assets/patterns/styles/grid-world.svg");
  --bg-image-project: url("./assets/patterns/styles/grid-projects.svg");
  --color-stroke: white;
  --color-fill: #ed0318;
  --color-t: var(--color-one);
  --color-tag: var(--color-dark);
  --color-outline: var(--color-two);
  --track-image: url("./assets/icons/scroll/track.svg"),
    linear-gradient(100deg, transparent, transparent);
  --thumb-image: url("./assets/icons/scroll/thumb.svg"),
    linear-gradient(100deg, transparent, transparent);
}

.app-light {
  --bg-main: #ebebeb;
  --color-clear: #505050;
  --color-dark: white;
  --tag-color: #1cc9df;
  --color-one: #ffc107;
  --color-two: #07fbff;
  --color-three: #e91e63;
  --op-color: #f2f2f287;
  --width-scrollbar: 0.8rem;
  --color-grey: #9e9e9e;
  --filter-invert-06x: invert(0.6);
  --filter-invert-1x: invert(1);
  --filter-drop-1x: none;
  --font-size-600: 600;
  --bg-image-home: url("./assets/patterns/styles/grid-world-two.svg");
  --bg-image-project: url("./assets/patterns/styles/grid-projects-two.svg");
  --color-stroke: #505050;
  --color-fill: #1cc9df;
  --color-t: var(--color-grey);
  --color-tag: var(--color-dark);
  --color-outline: var(--color-clear);
  --track-image: url("./assets/icons/scroll/track-light.svg"),
    linear-gradient(100deg, transparent, transparent);
  --thumb-image: url("./assets/icons/scroll/thumb-light.svg"),
    linear-gradient(100deg, transparent, transparent);
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --bg-main: #1a1a1a;
//     --color-clear: white;
//     --color-dark: black;
//     --tag-color: #ed0318;
//     --color-one: #ed03ce;
//     --color-two: #07fbff;
//     --color-three: #010df6;
//     --op-color: #00000087;
//     --width-scrollbar: 0.8rem;
//   }
// }

// @media (prefers-color-scheme: light) {
//   :root {
//     --bg-main: #ebebeb;
//     --color-clear: #505050;
//     --color-dark: white;
//     --tag-color: #1cc9df;
//     --color-one: #ed03ce;
//     --color-two: #07fbff;
//     --color-three: #010df6;
//     --op-color: #f2f2f287;
//     --width-scrollbar: 0.8rem;
//     --color-grey: #9e9e9e;
//   }

// }

$dir: "./assets/images/overlays/camera/";

$homeImg1: url($dir + "001.webp");
$homeImg2: url($dir + "002.webp");
$homeImg3: url($dir + "003.webp");

.home-enter-active {
  @include transition("homein", $homeImg1, $homeImg2, $homeImg3);
  animation: homein 0.35s;
}

$homeImg4: url($dir + "004.webp");
$homeImg5: url($dir + "041.webp");
$homeImg6: url($dir + "023.webp");

.home-leave-active {
  @include transition("homeout", $homeImg4, $homeImg5, $homeImg6);
  animation: homeout 0.35s;
}

$aboutImg1: url($dir + "007.webp");
$aboutImg2: url($dir + "022.webp");
$aboutImg3: url($dir + "028.webp");

.about-enter-active {
  @include transition("aboutin", $aboutImg1, $aboutImg2, $aboutImg3);
  animation: aboutin 0.35s;
}

$aboutImg4: url($dir + "005.webp");
$aboutImg5: url($dir + "012.webp");
$aboutImg6: url($dir + "035.webp");

.about-leave-active {
  @include transition("aboutout", $aboutImg4, $aboutImg5, $aboutImg6);
  animation: aboutout 0.35s;
}

$skillsImg1: url($dir + "024.webp");
$skillsImg2: url($dir + "036.webp");
$skillsImg3: url($dir + "018.webp");

.skills-enter-active {
  @include transition("skillsin", $skillsImg1, $skillsImg2, $skillsImg3);
  animation: skillsin 0.35s;
}

$skillsImg4: url($dir + "017.webp");
$skillsImg5: url($dir + "020.webp");
$skillsImg6: url($dir + "005.webp");

.skills-leave-active {
  @include transition("skillsout", $skillsImg4, $skillsImg5, $skillsImg6);
  animation: skillsout 0.35s;
}

$projectsImg1: url($dir + "010.webp");
$projectsImg2: url($dir + "018.webp");
$projectsImg3: url($dir + "038.webp");

.projects-enter-active {
  @include transition(
    "projectsin",
    $projectsImg1,
    $projectsImg2,
    $projectsImg3
  );
  animation: projectsin 0.35s;
}

$projectsImg4: url($dir + "008.webp");
$projectsImg5: url($dir + "018.webp");
$projectsImg6: url($dir + "029.webp");

.projects-leave-active {
  @include transition(
    "projectsout",
    $projectsImg4,
    $projectsImg5,
    $projectsImg6
  );
  animation: projectsout 0.35s;
}

$servicesImg1: url($dir + "041.webp");
$servicesImg2: url($dir + "025.webp");
$servicesImg3: url($dir + "026.webp");

.services-enter-active {
  @include transition(
    "servicesin",
    $servicesImg1,
    $servicesImg2,
    $servicesImg3
  );
  animation: servicesin 0.35s;
}

$servicesImg4: url($dir + "011.webp");
$servicesImg5: url($dir + "018.webp");
$servicesImg6: url($dir + "034.webp");

.services-leave-active {
  @include transition(
    "servicesout",
    $servicesImg4,
    $servicesImg5,
    $servicesImg6
  );
  animation: servicesout 0.35s;
}

$contactImg1: url($dir + "030.webp");
$contactImg2: url($dir + "040.webp");
$contactImg3: url($dir + "020.webp");

.contact-enter-active {
  @include transition("contactin", $contactImg1, $contactImg2, $contactImg3);
  animation: contactin 0.35s;
}

$contactImg4: url($dir + "027.webp");
$contactImg5: url($dir + "012.webp");
$contactImg6: url($dir + "031.webp");

.contact-leave-active {
  @include transition("contactout", $contactImg4, $contactImg5, $contactImg6);
  animation: contactout 0.35s;
}

// Animation frames
// Hom

::-webkit-scrollbar {
  width: var(--width-scrollbar);
}

::-webkit-scrollbar-track {
  background-image: var(--track-image);
  background-position: center;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
}

::-webkit-scrollbar-thumb {
  background-image: var(--thumb-image);
  background-position: center;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
}

[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
  line-height: 1;
  font-size: 0.9em;
  pointer-events: none;
  position: absolute;
  box-sizing: border-box;
  display: none;
  opacity: 0;
}

[data-tooltip]:before {
  content: "";
  border: 5px solid transparent;
  z-index: 100;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.4rem 1.3rem;
  font-family: "Hanson";
  font-weight: 100;
  font-size: 0.7rem;
  background-image: url("./assets/patterns/elements/tooltip.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  color: #000000;
  border-radius: 0.18rem;
  z-index: 99;
  border-left: 0.425rem black solid;
  border-right: 0.425rem black solid;
  outline: 0.125rem solid #ffffff73;
  filter: drop-shadow(0rem 0.06rem 0.37rem #ffffff73);
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after {
  display: block;
  opacity: 1;
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow="top"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: var(--color-clear);
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::after {
  bottom: calc(100% + 5px);
}

[data-tooltip]:not([data-flow])::before,
[tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::before,
[data-tooltip][data-flow="top"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, -4px);
  transform: translate(-50%, -4px);
}

[data-tooltip][data-flow="bottom"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: var(--color-clear);
}

[data-tooltip][data-flow="bottom"]::after {
  top: calc(100% + 5px);
}

[data-tooltip][data-flow="bottom"]::before,
[data-tooltip][data-flow="bottom"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, 8px);
  transform: translate(-50%, 8px);
}

[data-tooltip][data-flow="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: var(--color-clear);
  left: calc(0em - 5px);
  -webkit-transform: translate(-8px, -50%);
  transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  -webkit-transform: translate(-8px, -50%);
  transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: var(--color-clear);
  right: calc(0em - 5px);
  -webkit-transform: translate(8px, -50%);
  transform: translate(8px, -50%);
}

[data-tooltip][data-flow="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  -webkit-transform: translate(8px, -50%);
  transform: translate(8px, -50%);
}

[data-tooltip=""]::after,
[data-tooltip=""]::before {
  display: none !important;
}
</style>
