import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from "vue-i18n/index"
import messages from "./languages";
import { TroisJSVuePlugin } from 'troisjs';
import { createHead, VueHeadMixin } from "@unhead/vue"

export const i18n = createI18n({
    locale: 'en', 
    fallbackLocale: 'en',
    messages,
})

// createApp(App).use(router).use(i18n).use(VueMeta).use(TroisJSVuePlugin).mount('#app')
const app = createApp(App);
const head = createHead();
app.use(router);
app.use(i18n);
app.mixin(VueHeadMixin)
app.use(head)
app.use(TroisJSVuePlugin);
app.mount('#app');