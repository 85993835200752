<template>
  <section class="container-pagination">
    <div class="subcontainer-pagination">
      <router-link
        :to="{ name: page.name }"
        class="button-page"
        :class="page.name"
        v-for="(page, index) in pages"
        :key="index"
        :aria-label="`${$t('pagination.go')} ${page.name}`"
      >
        <!-- :data-tooltip="page.name" -->
        <!-- data-flow="top" -->
        <svg class="icon" viewBox="0 0 24 24" fill="currentColor">
          <g id="icon">
            <polygon
              points="20 14.36 20 19.29 12.71 12 20 4.71 20 9.36 21 9.36 21 3 14.36 3 14.36 4 19.29 4 12 11.29 4.71 4 9.36 4 9.36 3 3 3 3 9.64 4 9.64 4 4.71 11.29 12 4 19.29 4 14.64 3 14.64 3 21 9.64 21 9.64 20 4.71 20 12 12.71 19.29 20 14.64 20 14.64 21 21 21 21 14.36 20 14.36"
            />
          </g>
        </svg>
        <svg
          v-show="true"
          class="cursor-active"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <g id="icon">
            <path
              d="M18.53,16.37l-3.36-5.83A4.5,4.5,0,0,0,13,8.65l-1.66-.71s0-.07,0-.11L11,7.77l-.48-.21a.91.91,0,0,0-1.26.74L8.92,11a4.55,4.55,0,0,0,.58,2.79l3.36,5.82a1.83,1.83,0,0,0,2.49.67l2.52-1.46A1.8,1.8,0,0,0,18.53,16.37Z"
              transform="translate(0 0)"
            />
            <path
              d="M10.62,5.22,11.06,5a2.25,2.25,0,0,0-.24-.36l-.4.3A2.61,2.61,0,0,1,10.62,5.22Z"
              transform="translate(0 0)"
            />
            <path
              d="M9.07,3.47l-.39-.11-.12.48L8.93,4Z"
              transform="translate(0 0)"
            />
            <path
              d="M10.88,5.89l.48-.12a3.1,3.1,0,0,0-.13-.4l-.46.18C10.81,5.66,10.85,5.78,10.88,5.89Z"
              transform="translate(0 0)"
            />
            <path
              d="M9.85,3.79l-.38-.18-.19.46.33.16Z"
              transform="translate(0 0)"
            />
            <path
              d="M10.54,4.3A3,3,0,0,0,10.21,4l-.3.4.28.23Z"
              transform="translate(0 0)"
            />
            <path
              d="M12.86,7.74l.5,0c0-.12,0-.26,0-.4l-.5,0C12.84,7.49,12.85,7.61,12.86,7.74Z"
              transform="translate(0 0)"
            />
            <path
              d="M12.88,8.32V8.5l.5,0V8.11h-.5Z"
              transform="translate(0 0)"
            />
            <path
              d="M11,7.38l.5,0,0-.41L11,7C11,7.13,11,7.25,11,7.38Z"
              transform="translate(0 0)"
            />
            <path
              d="M11,6.63l.5,0c0-.14,0-.28,0-.41l-.5.06C11,6.38,11,6.5,11,6.63Z"
              transform="translate(0 0)"
            />
            <path
              d="M8.28,3.27l-.41-.06,0,.5.37,0Z"
              transform="translate(0 0)"
            />
            <path
              d="M8.35,7.66,8,8l.29.28.34-.35Z"
              transform="translate(0 0)"
            />
            <path
              d="M7.4,6.47,7,6.73c.07.12.14.24.22.35l.41-.29Z"
              transform="translate(0 0)"
            />
            <path
              d="M7.83,7.1l-.39.31.27.31.37-.34Z"
              transform="translate(0 0)"
            />
            <path
              d="M8.91,8.21l-.34.36.29.27.33-.37Z"
              transform="translate(0 0)"
            />
            <path
              d="M6.77,5.11l-.48.13a3.17,3.17,0,0,0,.14.4l.46-.18A2.91,2.91,0,0,1,6.77,5.11Z"
              transform="translate(0 0)"
            />
            <path
              d="M6.66,4.46v0l-.5,0v.07a2.46,2.46,0,0,0,0,.37l.49-.08A1.5,1.5,0,0,1,6.66,4.46Z"
              transform="translate(0 0)"
            />
            <path
              d="M7,5.8,6.59,6l.18.36.44-.24A3.59,3.59,0,0,1,7,5.8Z"
              transform="translate(0 0)"
            />
            <path
              d="M7.46,3.7l0-.5A2,2,0,0,0,7,3.27l.15.48A1.43,1.43,0,0,1,7.46,3.7Z"
              transform="translate(0 0)"
            />
            <path
              d="M6.88,3.88,6.56,3.5a1.15,1.15,0,0,0-.3.42l.46.18A.69.69,0,0,1,6.88,3.88Z"
              transform="translate(0 0)"
            />
            <polygon
              points="13.28 6.87 13.25 6.67 13 6.71 12.76 6.78 12.78 6.94 13.28 6.87"
            />
          </g>
        </svg>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      pages: [
        {
          name: "Home",
          path: "/",
          accessKey: "h",
        },
        {
          name: "AboutMe",
          path: "/about",
          accessKey: "a",
        },
        {
          name: "Skills",
          path: "/skills",
          accessKey: "s",
        },
        {
          name: "Services",
          path: "/services",
          accessKey: "i",
        },
        {
          name: "Projects",
          path: "/projects",
          accessKey: "p",
        },
        {
          name: "ContactMe",
          path: "/contact",
          accessKey: "c",
        },
      ],
      active: true,
      isShowed: false,
    };
  },
  computed: {
    actualRoute() {
      return this.$route.name;
    }
  },
  watch: {
    actualRoute(value) {
      if (value == "Home" && !this.isShowed ) {
        this.active = "flex"
        this.isShowed = true;
      } else {
        this.active = "none";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.container-pagination {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1.5rem;
  width: 100vw;
  z-index: 100;
  /* background-color:blue; */
  .subcontainer-pagination {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    
    .AboutMe{
      position:relative;
      &::after {
      content: "";
      display:v-bind(active);
      // background: blue;
      border-radius: 25%;
      // border-radius: 0.5rem;
      border: 0.01rem solid var(--color-clear);
      animation: wave 1s infinite;
      position: absolute;
      z-index: -1;
    }
    &::before {
      content: "";
      display:v-bind(active);
      border-radius: 25%;
      border: 0.01rem solid var(--color-clear);
      animation: wave-two 1s infinite;
      animation-delay: 0.2s;
      position: absolute;
      z-index: -1;
    }
  }
    /* background-color:grey; */
    .button-page {
      &:hover {
        .icon {
          transform: rotate(180deg);
        }
      }
      .icon {
        color: var(--color-clear);
        width: 1.6rem;
        position: relative;
        transition: all 0.4s linear;
      }
      .cursor-active {
        /* visibility: hidden; */
        display: none;
        position: absolute;
        top: 0.6rem;
        width: 2rem;
        /* transform: translate(-68%, -5%) rotate(0deg); */
        transform: translate(10%, -5%) rotate(0deg);
        animation: slide 2s linear;
        animation-play-state: paused;
        color: var(--tag-color);
      }
    }
    a.router-link-exact-active > .cursor-active {
      display: flex;
      /* visibility: visible; */
      animation-play-state: initial;
    }
  }
}

@keyframes slide {
  0% {
    /* transform: translate(-68%, -5%) rotate(0deg); */
    transform: translate(10%, -5%) rotate(0deg);
  }
  10% {
    /* transform: translate(-52%, -47%) rotate(-80deg); */
    transform: translate(26%, -47%) rotate(-80deg);
  }
  25% {
    /* transform: translate(-113%, -13%) rotate(80deg); */
    transform: translate(-55%, -13%) rotate(80deg);
  }
  45% {
    /* transform: translate(-68%, -5%) rotate(0deg); */
    transform: translate(10%, -5%) rotate(0deg);
  }
  65% {
    /* transform: translate(-103%, -5%) rotate(60deg); */
    transform: translate(-25%, -5%) rotate(60deg);
  }
  85% {
    transform: translate(-4%, -5%) rotate(20deg);
  }
  100% {
    transform: translate(10%, -5%) rotate(0deg);
  }
}
</style>
